<template>
	<footer class="footer">
		<Container>
			<p class="copyright">&copy;{{date.getFullYear()}}</p>
		</Container>
	</footer>
</template>

<script>
import Container from './Container'
export default {
	name: 'Footer',
	data() {
		return {
			date: new Date()
		}
	},
	components: {
		Container,
	},
}
</script>

<style lang="scss" scoped>
.footer {
	color: #000;
	text-align: center;
	padding: 15px 0;
}
</style>
